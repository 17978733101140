import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

import alreadyLoggedInGuard from "./guards/already-logged-in";
import {
  OPTIONAL_AUTH_GUARD_KEY,
  optionalAuthGuard,
} from "./guards/optional-auth";
import {
  REQUIRES_AUTH_GUARD_KEY,
  requiresAuthGuard,
} from "./guards/requires-auth";
import MaintenanceView from "@frontend/common/views/MaintenanceView.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/spots/:id?",
    name: "FindSpots",
    component: () => import("../views/FindSpotRequestView.vue"),
    meta: { [REQUIRES_AUTH_GUARD_KEY]: true },
    alias: "/",
    props: true,
  },
  {
    path: "/personalized-spots/:id?",
    name: "PersonalizedSpots",
    component: () => import("../views/PersonalizedSpotsView.vue"),
    meta: { [REQUIRES_AUTH_GUARD_KEY]: true },
  },
  {
    path: "/personalized-spots/:id/onboarding",
    component: () => import("../views/Auth/AuthLayout.vue"),
    meta: { [REQUIRES_AUTH_GUARD_KEY]: true },
    children: [
      {
        path: "",
        name: "BidView",
        component: () => import("../views/Onboarding/BidView.vue"),
        meta: { [REQUIRES_AUTH_GUARD_KEY]: true },
      },
      {
        path: "bid-success",
        name: "BidSuccessView",
        component: () => import("../views/Onboarding/BidSuccessView.vue"),
        meta: { [REQUIRES_AUTH_GUARD_KEY]: true },
      },
    ],
  },
  {
    path: "/auth",
    component: () => import("../views/Auth/AuthLayout.vue"),
    meta: { [REQUIRES_AUTH_GUARD_KEY]: false },
    children: [
      {
        path: "login",
        name: "Login",
        component: () => import("../views/Auth/LoginView.vue"),
      },
      {
        path: "change-password",
        name: "ChangePassword",
        component: () => import("../views/Auth/ChangePasswordView.vue"),
      },
      {
        path: "forgot-password",
        name: "ForgotPassword",
        component: () => import("../views/Auth/ForgotPasswordView.vue"),
      },
      {
        path: "signup",
        name: "Signup",
        component: () => import("../views/Auth/SignupView.vue"),
      },
      {
        path: "unsubscribe",
        name: "Unsubscribe",
        component: () => import("../views/Auth/UnsubscribeView.vue"),
      },
      {
        path: "unsubscribe/success",
        name: "UnsubscribeSuccess",
        component: () => import("../views/Auth/UnsubscribeSuccessView.vue"),
      },
    ],
  },
  {
    path: "/legal-notice",
    name: "LegalNotice",
    meta: { [OPTIONAL_AUTH_GUARD_KEY]: true },
    component: () => import("../views/LegalNoticeView.vue"),
  },
  {
    path: "/cookie-policy",
    name: "CookiePolicy",
    meta: { [OPTIONAL_AUTH_GUARD_KEY]: true },
    component: () => import("../views/CookiePolicyView.vue"),
  },
  {
    path: "/manage-my-bids",
    name: "ManageMyBids",
    meta: { [REQUIRES_AUTH_GUARD_KEY]: true },
    component: () => import("../views/ManageMyBidsView.vue"),
  },
  { path: "/:pathMatch(.*)*", redirect: "/" },
  {
    path: "/maintenance",
    name: "Maintenance",
    component: MaintenanceView,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(alreadyLoggedInGuard);
router.beforeEach(requiresAuthGuard);
router.beforeEach(optionalAuthGuard);

export default router;

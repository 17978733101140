import { Notify } from "quasar";
import { I18n } from "vue-i18n";

export default function useInternalError(i18n: I18n) {
  const { t } = i18n.global as unknown as { t: (key: string) => string };

  function onFetchError(ctx: { error: Error; response: Response | null }) {
    if (
      ctx.response?.status === 503 &&
      ctx.error.message.includes("app.maintenance_mode")
    ) {
      window.location.href = "/maintenance";
    }

    if (ctx.error.message.includes("Internal Server Error")) {
      Notify.create({
        type: "negative",
        message: t("common.errors.internal-error"),
      });
    }

    return Promise.resolve(ctx);
  }

  return {
    onFetchError,
  };
}

import { Notify } from "quasar";

Notify.setDefaults({
  timeout: 3000,
  position: "top-right",
  badgeStyle: { display: "none" },
});

/**
 * Override the default notify types to avoid
 * repeating the same options whenever we need to call notify.
 */
Notify.registerType("negative", {
  icon: "error_outline",
  textColor: "negative",
  color: "red-1",
  actions: [
    {
      icon: "close",
      color: "negative",
    },
  ],
});

Notify.registerType("positive", {
  icon: "check",
  textColor: "positive",
  color: "green-1",
  actions: [
    {
      icon: "close",
      color: "positive",
    },
  ],
});

Notify.registerType("info", {
  icon: "uil-info-circle",
  textColor: "primary",
  color: "blue-1",
  actions: [
    {
      icon: "close",
      color: "primary",
    },
  ],
});

export default Notify;

import { defineStore } from "pinia";
import { RemovableRef, useStorage } from "@vueuse/core";

export interface IUser {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  locale: string;
  hasAcceptedTos: boolean;
  phoneNumber: string;
  isOnboarded: boolean;
  carrier: ICarrier | null;
  dispatcher: IDispatcher | null;
}

export interface ICarrier {
  id: string;
  companyName: string;
  isSubscribedToNotifications: boolean;
  isCarrierClub: boolean;
}

export interface IDispatcher {
  id: string;
  centerId: string;
}

interface IState {
  user: IUser | null;
  accessToken: RemovableRef<string | null>;
  notificationDialogStatus: "initialize" | "edit" | null;
}

export function defineAuthStore() {
  return defineStore("Auth", {
    state: (): IState => ({
      user: null,
      accessToken: useStorage<string | null>("accessToken", null),
      notificationDialogStatus: null,
    }),
    getters: {
      getFullName: (state) => {
        if (!state.user) return "";

        const { firstName, lastName } = state.user;
        return `${firstName} ${lastName}`;
      },
      getDisplayEmail: (state) => {
        const email = state.user?.email;
        if (email === undefined) {
          return "";
        }

        return email.split("@")[0];
      },
      getEmailAddress: (state): string => {
        const email = state.user?.email;
        if (email === undefined) {
          return "";
        }

        return email;
      },
      getUser: (state) => {
        if (!state.user) throw "No user defined.";

        return state.user;
      },
      isAuthenticated: (state): boolean => {
        return !!(state.user && state.accessToken);
      },
    },
    actions: {
      setUser(user: IUser) {
        this.user = user;
      },
      saveToken(accessToken: string | null) {
        this.accessToken = accessToken;
      },
    },
  });
}

import { NavigationGuardNext, RouteLocationNormalized } from "vue-router";

import { useAuth } from "@/composables/useAuth";

import { REQUIRES_AUTH_GUARD_KEY } from "./requires-auth";

/**
 * Will intercept navigation to the login view. If the user is already logged in,
 * redirect him to the spots list.
 */
export default async function alreadyLoggedInGuard(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) {
  if (to.meta[REQUIRES_AUTH_GUARD_KEY] !== false) return next();

  const { getValidAccessToken } = useAuth();
  const accessToken = await getValidAccessToken();

  if (accessToken) {
    return next({
      name: "FindSpots",
    });
  }

  next();
}

import { NavigationGuardNext, RouteLocationNormalized } from "vue-router";

import { useAuth } from "@/composables/useAuth";
import { setLocale } from "@/plugins/i18n";

export const OPTIONAL_AUTH_GUARD_KEY = "optionalAuth";

export async function optionalAuthGuard(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) {
  if (!to.meta[OPTIONAL_AUTH_GUARD_KEY]) {
    return next();
  }

  const { getValidAccessToken, fetchUser } = useAuth();

  const accessToken = await getValidAccessToken();

  if (accessToken === null) {
    return next();
  }

  const user = await fetchUser(accessToken);
  if (user) setLocale(user.locale);

  next();
}

import { createI18n } from "vue-i18n";
import dayjs from "dayjs";
import { Quasar, QuasarLanguage } from "quasar";
import { LocaleMessage } from "@intlify/core-base";
import { nextTick } from "vue";
import { localize as setValidateLocale } from "@vee-validate/i18n";

import "dayjs/locale/fr";
import "dayjs/locale/en";
import "dayjs/locale/es";
import "dayjs/locale/pl";

import quasarFr from "quasar/lang/fr";
import quasarEs from "quasar/lang/es";
import quasarPl from "quasar/lang/pl";

export type AvailableLocales = "en" | "fr" | "es" | "pl";

const quasarLocales: { [key: string]: QuasarLanguage } = {
  fr: quasarFr,
  es: quasarEs,
  pl: quasarPl,
};

export interface I18nMessage {
  [key: string]: LocaleMessage<unknown>;
}

export function createCommonI18n(
  translations: Record<
    string,
    {
      [key: string]: string;
    }
  >,
  initialMessage: I18nMessage,
  forcedLocale?: string,
  localeCallback?: (locale: string) => void
) {
  const messages: I18nMessage = Object.assign(
    { en: {}, fr: {}, es: {}, pl: {} },
    initialMessage
  );

  const determineLocale = () => {
    if (forcedLocale) return forcedLocale;

    return window.navigator.language.slice(0, 2);
  };

  Object.keys(translations).forEach((filePath) => {
    const pathRegexp = /locales\/(\w+)\/(.+).json/gi;
    const matches = pathRegexp.exec(filePath);
    if (matches && matches[1] && matches[2]) {
      messages[matches[1]][matches[2]] = translations[filePath];
    }
  });

  const inTest = process.env.NODE_ENV === "test";

  const i18n = createI18n({
    fallbackLocale: "en",
    globalInjection: true,
    legacy: false,
    allowComposition: true,
    locale: determineLocale(),
    missing: inTest
      ? (locale, key) => {
          return key;
        }
      : undefined,
    messages,
  });

  const setLocale = async (localeToSet: string) => {
    dayjs.locale(localeToSet);
    i18n.global.locale.value = localeToSet;
    if (typeof setValidateLocale !== "undefined")
      setValidateLocale(localeToSet);

    if (typeof localeCallback !== "undefined") localeCallback(localeToSet);

    try {
      if (!quasarLocales[localeToSet]) return;

      /**
       * Await for the nextTick to make sure the i18n.locale has been updated
       * as it's used by Quasar to know which locale to use.
       */
      await nextTick();
      Quasar.lang.set(quasarLocales[localeToSet]);
    } catch (err) {
      console.error(err);
    }
  };

  setLocale(determineLocale());

  return {
    i18n,
    determineLocale,
    setLocale,
  };
}

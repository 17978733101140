import { defineStore } from "pinia";
import { useI18n } from "vue-i18n";
import { ref } from "vue";
import axios from "axios";
import { useQuasar } from "./useQuasar";

export const defineVersionCheckerStore = (version: string) => {
  return defineStore("versionChecker", () => {
    const { t } = useI18n();
    const { notify } = useQuasar();

    const interval = ref<null | NodeJS.Timeout>(null);
    const requiresUpdate = ref<boolean>(false);

    const setInterval = (newInterval: NodeJS.Timeout | null) => {
      interval.value = newInterval;
    };
    const setRequiresUpdate = (requires: boolean) => {
      requiresUpdate.value = requires;

      if (requires && interval.value) {
        clearInterval(interval.value);
        setInterval(null);
      }
    };
    const checkForUpdate = async () => {
      try {
        const { status, data } = await axios.get("/config.json", {
          headers: {
            "Cache-Control": "no-cache",
            Pragma: "no-cache",
            Expires: "0",
          },
        });
        if (status === 200) {
          const publicVersion = data.version;
          if (version !== publicVersion && !requiresUpdate.value) {
            setRequiresUpdate(true);

            notify({
              message: t("common.informations.update_available"),
              type: "info",
              position: "top-right",
              actions: [
                {
                  label: t("common.buttons.reload"),
                  handler: () => {
                    window.location.reload();
                  },
                },
              ],
              onDismiss: () => setRequiresUpdate(false),
              timeout: 0,
            });
          } else {
            setRequiresUpdate(false);
          }
        }
      } catch (error) {
        // Do nothing: config.json should always be available
      }
    };

    return {
      interval,
      requiresUpdate,
      checkForUpdate,
      setInterval,
      setRequiresUpdate,
    };
  });
};

import { NavigationGuardNext, RouteLocationNormalized } from "vue-router";

import { isBidBeforeLoginToken, useAuth } from "@/composables/useAuth";
import { setLocale } from "@/plugins/i18n";

export const REQUIRES_AUTH_GUARD_KEY = "requiresAuth";

/**
 * Will intercept navigation to any route that requires authentication.
 * When no token is available in local storage or in query param, redirect to the login page.
 * If token in query param, try to fetch user and redirect to signup if user is not onboarded.
 */
export async function requiresAuthGuard(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) {
  if (!to.meta[REQUIRES_AUTH_GUARD_KEY]) {
    return next();
  }

  const { getValidAccessToken, fetchUser, setToken } = useAuth();

  const accessToken = await getValidAccessToken();

  try {
    const tokenToUse = (to.query.token || accessToken) as string;

    if (!tokenToUse) {
      return next({ name: "Login", query: { redirect: to.fullPath } });
    }

    if (isBidBeforeLoginToken(tokenToUse)) {
      setToken(tokenToUse);
      await handleBidBeforeLogin(to, next);
      return;
    }

    const user = await fetchUser(tokenToUse);

    if (user) {
      setToken(tokenToUse);
      await setLocale(user.locale);
    }

    next();
  } catch (e) {
    next({ name: "Login", query: { redirect: to.fullPath } });
  }
}

async function handleBidBeforeLogin(
  to: RouteLocationNormalized,
  next: (v?: unknown) => void
) {
  const onboardingRoutes = ["BidView", "BidSuccessView"];
  if (to.name === "PersonalizedSpots") {
    next({
      name: "BidView",
      params: {
        id: to.params.id,
      },
      query: {
        token: to.query.token,
      },
    });
  } else if (!onboardingRoutes.includes(to.name as string)) {
    next({
      name: "Signup",
      query: { token: to.query.token, redirect: to.path, fromBid: "true" },
    });
  } else {
    next();
  }
}

import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";
import { App } from "vue";

type SentryPluginOptions = {
  dsn: string;
  environment: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  router: any;
};

export const sentry = {
  install(app: App, { router, dsn, environment }: SentryPluginOptions) {
    Sentry.init({
      app,
      dsn,
      environment,
      integrations: [
        new BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(router),
          tracingOrigins: ["localhost", "carrier-portal-gefco.net", /^\//],
        }),
      ],
      tracesSampleRate: 1.0,
      ignoreErrors: ["ResizeObserver loop limit exceeded"],
    });
  },
};

import { definePlugin } from "@frontend/common/plugins/vee-validate";

import validateEn from "@/locales/en/validate.json";
import validateEs from "@/locales/es/validate.json";
import validateFr from "@/locales/fr/validate.json";

definePlugin({
  en: {
    messages: validateEn.messages,
  },
  es: {
    messages: validateEs.messages,
  },
  fr: {
    messages: validateFr.messages,
  },
});

import { useFetch } from "@vueuse/core";
import dayjs from "dayjs";
import jwtDecode from "jwt-decode";
import { StoreGeneric } from "pinia";
import { ref } from "vue";
import { IUser } from "./defineAuthStore";

type AccessToken = string | null;

interface IAccessTokenPayload {
  exp: number;
  roles: string[];
}

interface IUseAuthOptions {
  expectedRole: string;
  authStore: () => StoreGeneric;
  fetch: typeof useFetch;
}

export interface IAuthLoginArgs {
  (
    formData: {
      email: string;
      password: string;
    },
    successCallback: () => void,
    errorCallback: () => void
  ): Promise<void>;
}

export function isTokenValid(token: string, expectedRole: string) {
  try {
    const decodedToken = jwtDecode<IAccessTokenPayload>(token);
    if (isTokenExpired(token)) return false;

    return decodedToken.roles.includes(expectedRole);
  } catch (e) {
    return false;
  }
}

export function isTokenExpired(token: string) {
  try {
    const decodedToken = jwtDecode<IAccessTokenPayload>(token);
    if (!decodedToken.exp) {
      return true;
    }

    return dayjs().isAfter(dayjs.unix(decodedToken.exp));
  } catch (e) {
    return true;
  }
}

export const useAuth = (options: IUseAuthOptions) => () => {
  const refreshTokenPromise = ref<Promise<AccessToken> | null>(null);

  const setToken = (accessToken: AccessToken) => {
    options.authStore().saveToken(accessToken);
  };

  const fetchUser = async (token: string) => {
    if (options.authStore().user) {
      return;
    }

    const { data, error } = await options
      .fetch("/me", { headers: { Authorization: `Bearer ${token}` } })
      .get()
      .json<IUser>();

    if (error.value || !data.value) {
      throw error.value;
    }

    options.authStore().setUser(data.value);

    return data.value;
  };

  const login: IAuthLoginArgs = async (
    formData,
    successCallback,
    errorCallback
  ) => {
    const { email, password } = formData;
    const { data, statusCode } = await options
      .fetch("/auth/jwt/create")
      .post({ email, password })
      .json();

    const error = statusCode.value === 401;
    if (error || data.value === null) {
      errorCallback();
      return;
    }

    if (!isTokenValid(data.value.access, options.expectedRole)) {
      errorCallback();
      return;
    }

    setToken(data.value.access);

    successCallback();
  };

  const refreshToken = (): Promise<AccessToken> => {
    if (refreshTokenPromise.value !== null) {
      return refreshTokenPromise.value;
    }

    refreshTokenPromise.value = new Promise((resolve) => {
      const { data, onFetchError, onFetchFinally, onFetchResponse } = options
        .fetch("/auth/jwt/refresh")
        .post(null)
        .json<{ access: string }>();

      onFetchError(() => {
        logout();
        resolve(null);
      });

      onFetchResponse(() => {
        if (data.value !== null) {
          setToken(data.value.access);
        }

        resolve(data.value?.access || null);
      });

      onFetchFinally(() => {
        refreshTokenPromise.value = null;
      });
    });

    return refreshTokenPromise.value;
  };

  const logout = async (logoutCallback?: () => Promise<void>) => {
    await options.fetch("/auth/jwt/logout").post({});
    options.authStore().saveToken(null);
    localStorage.removeItem("accessToken");

    /**
     * Callback to be run after the token is clear, so we can
     * redirect the user before totally resetting the store.
     * This ensures we do not have a glitch for a few seconds where the data
     * is not present anymore but the user remains in the page.
     */
    if (logoutCallback) await logoutCallback();
    options.authStore().$reset();

    if (window.location.pathname !== "/auth/login") {
      window.location.href = "/auth/login";
    }
  };

  const getValidAccessToken = (): Promise<AccessToken> => {
    const accessToken = options.authStore().accessToken;
    if (accessToken === null) {
      return Promise.resolve(null);
    }

    if (isTokenExpired(accessToken)) {
      return refreshToken();
    }

    return Promise.resolve(accessToken);
  };

  return {
    fetchUser,
    login,
    logout,
    refreshToken,
    getValidAccessToken,
    setToken,
  };
};

interface PluginInstallOptions {
  $q: {
    iconMapFn: (iconName: string) => { cls: string } | void;
  };
}

export default {
  install({ $q }: PluginInstallOptions) {
    /**
     * Use a custom icon mapping for Uil icons, as they are not supported out of the box
     * by Quasar.
     * See: https://quasar.dev/vue-components/icon#1-support-for-custom-icon-library
     */
    $q.iconMapFn = (iconName) => {
      if (iconName.startsWith("uil-")) {
        return {
          cls: `uil ${iconName}`,
        };
      }
    };
  },
};

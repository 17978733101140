import { App } from "vue";
import { I18n } from "vue-i18n";

type AxeptioPluginOptions = {
  i18n: I18n;
};

type AxeptioSettings = {
  clientId: string;
  cookiesVersion: string;
};

type WindowAxeptio = Window & {
  axeptioSettings: AxeptioSettings;
  axeptioSDK?: {
    setCookiesVersion: (cookiesVersion: string) => void;
  };
};

export default {
  getSupportedLocale(locale: string) {
    const SUPPORTED_AXEPTIO_LOCALES = ["en", "es", "fr", "pl"];
    if (!SUPPORTED_AXEPTIO_LOCALES.includes(locale)) {
      console.warn(
        `Our Axeptio integration currently does not support the locale "${locale}". Fallbacking to "en".`
      );

      return "en";
    }

    return locale;
  },
  injectScript(locale: string) {
    (window as unknown as WindowAxeptio).axeptioSettings = {
      clientId: process.env.VITE_AXEPTIO_CLIENT_ID as string,
      cookiesVersion: `${
        process.env.VITE_AXEPTIO_COOKIES_VERSION as string
      }-${this.getSupportedLocale(locale)}`,
    };

    const target = document.getElementsByTagName("head")[0];
    if (target) {
      const scriptElement = document.createElement("script");
      scriptElement.setAttribute("src", "//static.axept.io/sdk.js");
      scriptElement.setAttribute("async", "");

      target.appendChild(scriptElement);
    }
  },
  install(app: App, options: AxeptioPluginOptions) {
    const localeToUse = options.i18n.global.locale;
    this.injectScript(
      typeof localeToUse === "string" ? localeToUse : localeToUse.value
    );
  },
  setLocale(locale: string) {
    const _window = window as unknown as WindowAxeptio;
    if (_window.axeptioSDK) {
      _window.axeptioSDK.setCookiesVersion(
        `${
          process.env.VITE_AXEPTIO_COOKIES_VERSION as string
        }-${this.getSupportedLocale(locale)}`
      );
    }
  },
};

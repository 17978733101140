import { createApp } from "vue";
import { createPinia } from "pinia";
import { Quasar, QuasarPluginOptions } from "quasar";
import VueGtag from "vue-gtag";

import "@quasar/extras/material-icons/material-icons.css";
import "quasar/src/css/index.sass";
import UilIcon from "@frontend/common/plugins/quasar/uil-icon";
import Notify from "@frontend/common/plugins/quasar/notify";

import "./plugins/vee-validate";
import App from "./App.vue";
import router from "./router";
import { i18n } from "./plugins/i18n";
import axeptio from "./plugins/axeptio";
import { VueWait } from "@frontend/common/plugins/vue-wait";
import { sentry } from "@frontend/common/plugins/sentry";
import Hotjar from "@frontend/common/plugins/hotjar";
import VersionCheckerPlugin from "./plugins/version-checker";

const app = createApp(App);

app
  .use(createPinia())
  .use(router)
  .use(i18n)
  .use(sentry, {
    dsn: process.env.VITE_SENTRY_DSN as string,
    environment: process.env.VITE_SENTRY_ENV as string,
    router,
  })
  .use(axeptio, {
    i18n,
  })
  .use(Quasar, {
    plugins: { Notify, UilIcon },
  } as Partial<QuasarPluginOptions>)
  .use(
    VueGtag,
    {
      config: {
        id: process.env.VITE_GOOGLE_ANALYTICS_ID as string,
      },
    },
    router
  )
  .use(VueWait)
  .use(Hotjar, {
    id: process.env.VITE_HOTJAR_ID as string,
  })
  .use(VersionCheckerPlugin)
  .mount("#app");

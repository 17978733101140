import { defineRule, configure } from "vee-validate";
import {
  required,
  min_value as minValue,
  min,
  max,
  email,
  confirmed,
} from "@vee-validate/rules";
import { localize } from "@vee-validate/i18n";
import dayjs from "dayjs";

import en from "@vee-validate/i18n/dist/locale/en.json";
import es from "@vee-validate/i18n/dist/locale/es.json";
import fr from "@vee-validate/i18n/dist/locale/fr.json";

const isDateAfter = (
  value: string,
  params: { value: string; date: string }
): boolean => {
  return dayjs(value).isSameOrAfter(params.value, "day");
};

const isDateTimeAfter = (
  value: string,
  params: { value: string; date: string }
): boolean => {
  return dayjs(value).isSameOrAfter(params.value, "minute");
};

export const greaterThan = (
  value: number | null,
  greaterThan: string
): boolean => {
  if (value || value === 0) {
    return value > Number(greaterThan);
  }

  return true;
};

export function definePlugin(
  baseDictionnary: Record<string, Record<string, unknown>>
) {
  defineRule("min", min);
  defineRule("max", max);
  defineRule("min_value", minValue);
  defineRule("greater_than", greaterThan);
  defineRule("required", required);
  defineRule("email", email);
  defineRule("confirmed", confirmed);

  defineRule("is_date_after", isDateAfter);
  defineRule("is_datetime_after", isDateTimeAfter);

  configure({
    generateMessage: localize({
      en: {
        ...en,
        messages: Object.assign(en.messages, baseDictionnary.en.messages),
      },
      es: {
        ...es,
        messages: Object.assign(es.messages, baseDictionnary.es.messages),
      },
      fr: {
        ...fr,
        messages: Object.assign(fr.messages, baseDictionnary.fr.messages),
      },
    }),
  });
}

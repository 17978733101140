import { useVersionCheckerStore } from "@/stores/versionChecker";

type VersionCheckerStore = ReturnType<typeof useVersionCheckerStore>;

class VersionChecker {
  private store: VersionCheckerStore;
  constructor(store: VersionCheckerStore) {
    this.store = store;

    if (!store.interval) {
      // allow us to check for updates on the first load
      this.checkForUpdate().then(() => {
        const updateInterval = setInterval(() => this.checkForUpdate(), 30000);
        store.setInterval(updateInterval);
      });
    }
  }

  async checkForUpdate() {
    await this.store.checkForUpdate();
  }
}

export default {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  install(app: any) {
    app.mixin({
      beforeCreate() {
        const store = useVersionCheckerStore();

        if (!store || store.interval !== null) {
          return;
        }

        new VersionChecker(store);
      },
    });
  },
};

import axeptio from "@/plugins/axeptio";
import { createCommonI18n, I18nMessage } from "@frontend/common/plugins/i18n";

import en from "../locales/en.json";
import fr from "../locales/fr.json";
import es from "../locales/es.json";
import pl from "../locales/pl.json";

const baseMessages: I18nMessage = { en, fr, es, pl };

const localeCallback = (locale: string) => {
  axeptio.setLocale(locale);
};

const translations = import.meta.globEager(
  "../../../../(apps|libs)/(carrier|common)/**/locales/*/*.json"
);

const { i18n, setLocale } = createCommonI18n(
  translations,
  baseMessages,
  undefined,
  localeCallback
);

export { i18n, setLocale };

import { useAuth as useAuthCommon } from "@frontend/common/composables/useAuth";

import { useAuthStore } from "@/stores/auth";
import jwtDecode from "jwt-decode";
import { useSpotsFetchWithoutAuth } from "@/modules/useSpotsFetchWithoutAuth";

export const useAuth = useAuthCommon({
  expectedRole: "ROLE_CARRIER_CLUB",
  authStore: useAuthStore,
  fetch: useSpotsFetchWithoutAuth,
});

export interface BidBeforeLoginToken {
  email?: string;
  jfono?: string;
  companyName?: string;
  purpose?: string;
  userId?: string;
}

export const isBidBeforeLoginToken = (token: string): boolean => {
  const { email, companyName, jfono } = jwtDecode<BidBeforeLoginToken>(token);
  return !!email && !!companyName && !!jfono;
};

export const getBidBeforeLoginInfo = (token: string) => {
  const { email, jfono, companyName, userId } =
    jwtDecode<BidBeforeLoginToken>(token);

  if (!email || !jfono || !companyName) {
    throw new Error("Required fields not found in token");
  }

  return {
    email,
    companyName,
    isCarrierClub: !!userId,
  };
};

import { createFetch } from "@vueuse/core";

import useInternalError from "@frontend/common/composables/useInternalError";
import { i18n } from "@/plugins/i18n";

const { onFetchError } = useInternalError(i18n);
export const useSpotsFetchWithoutAuth = createFetch({
  baseUrl: process.env.VITE_SPOT_SERVICE_URL,
  options: {
    onFetchError,
  },
  fetchOptions: {
    credentials: "include",
  },
});
